// import React, { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import Card from './Card';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';

function ContactScreen(props) {
    let { name, userType } = useParams();
  const API = axios.create({
    // baseURL: 'https://api.github.com/users/ChethanVe',
    baseURL: userType === "" ? `https://api.github.com/orgs/redhoodai` : `https://api.github.com/${userType}/${name}`,
  });
  
  // console.log('props', name, userType);
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getPost() {
      const response = await API.get();
      console.log(response);
      setData(response.data);
    }
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider   theme={theme}>
      {data ? <Card data={data} /> : <Loading />}
    </ChakraProvider>
  );
}

export default ContactScreen;
