import React from "react";
import './styles.css'

const index = () => {
  return (
    <div class="loader">
      <div class="scanner">
        <span>redhood.ai</span>
      </div>
    </div>
  );
};

export default index;
