import React from 'react';
import ContactScreen from './Component/ContactScreen';
import { Routes, Route } from 'react-router-dom';
import WelcomeScreen from './Component/welcomeScreen';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomeScreen />} />
      <Route path="/:userType/:name" element={<ContactScreen />} />
    </Routes>
  );
};

export default App;
