// import React, { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { Center, ChakraProvider, Flex, theme } from '@chakra-ui/react';
import Card from './Card';
// import { useParams } from 'react-router-dom';
import axios from 'axios';

import Loading from './Loading';

function WelcomeScreen(props) {
  // let { name, userType } = useParams();
  const API = axios.create({
    // baseURL: 'https://api.github.com/users/avinaash-sharma',
    baseURL: `https://api.github.com/orgs/redhoodai`,
  });

  // console.log('props', name, userType);
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getPost() {
      const response = await API.get();
      setData(response.data);
    }
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Center h="100vh">
        {data ? (
          <Card data={data} />
        ) : (
          <Flex>
            <Loading />
          </Flex>
        )}
      </Center>
    </ChakraProvider>
  );
}

export default WelcomeScreen;
