import React, { useState } from 'react';
import {
  chakra,
  Box,
  Image,
  Flex,
  Icon,
  useColorModeValue,
  Button,
  Stack,
} from '@chakra-ui/react';
import './card.css';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { MdEmail, MdLocationOn, MdCall } from 'react-icons/md';
import {
  RiTwitterLine,
  RiLinkedinBoxFill,
  RiFacebookBoxFill,
  RiInstagramFill,
} from 'react-icons/ri';
import redhoodLogo from './image/redhood_logo.png';

const Card = props => {
  const [data] = useState(props.data);
  return (
    <Flex
      bg={useColorModeValue('#F9FAFB', 'gray.800')}
      p={{ base: 5, md: 10 }}
      height={'-webkit-fit-content'}
      w="full"
      alignContent={'center'}
      justifyContent="center"
    >
      <Box
        w="sm"
        mx="auto"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="lg"
        rounded={{ base: 'md', md: 'lg' }}
        overflow="hidden"
      >
        <Image
          w="full"
          h={56}
          fit="cover"
          objectPosition="center"
          src={data.avatar_url !== null ? data.avatar_url : 'Loading'}
          alt="avatar"
        />

        <Stack
          pos="relative"
          bottom="7"
          alignItems="center"
          w="full"
          h={'7'}
          color="white"
          background={'gray.100'}
          opacity={'0.6'}
        >
          <Image
            boxSize="50%"
            h={'7'}
            fit="contain"
            src={redhoodLogo}
            alt="redhood_logo"
          />
        </Stack>

        <Box pb={5} px={6}>
          <Flex
            justifyContent={'space-between'}
            color={useColorModeValue('gray.700', 'gray.200')}
          >
            <chakra.h1
              fontSize="xl"
              fontWeight="bold"
              
              color={useColorModeValue('#ae0000', 'gray.200')}
            >
            <a href='http://redhood.ai'>
              {data.name}
              </a>
            </chakra.h1>
            <ColorModeSwitcher />
          </Flex>
          <chakra.p
            fontSize="sm"
            py={2}
            color={useColorModeValue('gray.700', 'white')}
          >
            {data.description}
          </chakra.p>

          <Flex
            alignItems="center"
            mt={4}
            color={useColorModeValue('gray.700', 'gray.200')}
          >
            <Icon as={MdLocationOn} color="#ae0000" h={6} w={6} mr={2} />

            <chakra.h1 px={2} fontSize="sm">
              {data.location}
            </chakra.h1>
          </Flex>
          <Flex pt={5} flexDirection={'row'} justifyContent={'space-between'}>
            <Box
              alignItems="center"
              mt={4}
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              <a href="https://twitter.com/redhoodai">
                <Icon as={RiTwitterLine} color="#ae0000" h={6} w={6} mr={2} />
              </a>
            </Box>

            <Box
              alignItems="center"
              mt={4}
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              <a href="mailto:info@redhood.ai">
                <Icon as={MdEmail} color="#ae0000" h={6} w={6} mr={2} />
              </a>
            </Box>
            <Box
              alignItems="center"
              mt={4}
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              <a href="tel:+91 8892453988">
                <Icon as={MdCall} color="#ae0000" h={6} w={6} mr={2} />
              </a>
            </Box>

            <Box
              alignItems="center"
              mt={4}
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              <a href="https://www.linkedin.com/company/redhoodai/">
                <Icon
                  as={RiLinkedinBoxFill}
                  color="#ae0000"
                  h={6}
                  w={6}
                  mr={2}
                />
              </a>
            </Box>

            <Box
              alignItems="center"
              mt={4}
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              <a href="https://www.facebook.com/redhoodai">
                <Icon
                  as={RiFacebookBoxFill}
                  color="#ae0000"
                  h={6}
                  w={6}
                  mr={2}
                />
              </a>
            </Box>
            <Box
              alignItems="center"
              mt={4}
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              <a href="https://www.instagram.com/redhood.ai/">
                <Icon as={RiInstagramFill} color="#ae0000" h={6} w={6} mr={2} />
              </a>
            </Box>
          </Flex>
          <Flex
            alignItems="center"
            mt={4}
            color={useColorModeValue('#ae0000', 'gray.200')}
          >
            <Button w={'full'}>
              <a href={require('./redhood.ai.vcf')} download="user.vcf">
                Save as contact
              </a>
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Card;
